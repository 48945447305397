<template>
    <div class="container content-width">
        <div class="block"></div>

        <div class="ts-measure ts-measure-center">
            <h1 class="page-title">{{ $t("title") }}</h1>
        </div>

        <Clause :heading="$t('clauses.clause01.heading')" :html="$t('clauses.clause01.html')" />

        <Clause :heading="$t('clauses.clause02.heading')" :html="$t('clauses.clause02.html')" />

        <Clause :heading="$t('clauses.clause03.heading')" :html="$t('clauses.clause03.html')" />

        <div class="block-xl">
            <div class="terms-clause">
                <h3>{{ $t("clauses.clause04.heading") }}</h3>
                <div class="ts-quiet">
                    <div class="content" v-html="$t('clauses.clause04.html1')"></div>
                    <i18n path="clauses.clause04.html2" tag="p">
                        <i18n-link class="ts-underline" place="link" :to="{ name: 'contact' }">{{
                            $t("label.contact_form")
                        }}</i18n-link>
                    </i18n>
                </div>
            </div>
        </div>

        <Clause :heading="$t('clauses.clause05.heading')" :html="$t('clauses.clause05.html')" />

        <Clause :heading="$t('clauses.clause06.heading')" :html="$t('clauses.clause06.html')" />

        <Clause :heading="$t('clauses.clause07.heading')" :html="$t('clauses.clause07.html')" />

        <div class="block-xl">
            <div class="terms-clause">
                <h3>{{ $t("clauses.clause08.heading") }}</h3>
                <div class="ts-quiet">
                    <i18n path="clauses.clause08.html" tag="div">
                        <i18n-link
                            class="ts-underline"
                            place="link"
                            :to="{ name: 'compatibility' }"
                            >{{ $t("menu.compatibility") }}</i18n-link
                        >
                    </i18n>
                </div>
            </div>
        </div>

        <Clause :heading="$t('clauses.clause09.heading')" :html="$t('clauses.clause09.html')" />

        <Clause :heading="$t('clauses.clause10.heading')" :html="$t('clauses.clause10.html')" />

        <Clause
            class="no-border"
            :heading="$t('clauses.clause11.heading')"
            :html="$t('clauses.clause11.html')"
        />
    </div>
</template>
<script>
import Clause from "@/components/Clause"

export default {
    components: {
        Clause,
    },
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.sct.title"),
        })
    },
    data() {
        return {
            imageToggle: "balance",
        }
    },
    i18n: {
        messages: {
            en: {
                title: "特定商取引に関する法律に基づく表示",
                clauses: {
                    clause01: {
                        heading: "販売事業者",
                        html: `
                    <p class="ts-quiet">ROKKODE 株式会社</p>
                    `,
                    },
                    clause02: {
                        heading: "代表責任者",
                        html: `
                    <p class="ts-quiet">小山 修平</p>
                    `,
                    },
                    clause03: {
                        heading: "所在地",
                        html: `
                    <p class="ts-quiet">〒650-0022 兵庫県神戸市中央区元町通6-1-8 東栄ビル6F</p>
                    `,
                    },
                    clause04: {
                        heading: "お問い合わせ先",
                        html1: `
                    <p class="ts-quiet">Email: <a class="ts-underline" href="mailto:support@storkmobile.com">support@storkmobile.com</a><br />TEL: 078-599-9929</p>
                    `,
                        html2: `尚、電話サポートは行っておりませんので、{link}よりお問い合わせください。`,
                    },
                    clause05: {
                        heading: "販売価格",
                        html: `
                    <p class="ts-quiet">記載の価格をご参照ください。</p>
                    `,
                    },
                    clause06: {
                        heading: "商品代金以外の必要料金",
                        html: `
                    <p class="ts-quiet">なし</p>
                    `,
                    },
                    clause07: {
                        heading: "商品の引渡時期",
                        html: `
                    <p class="ts-quiet">クレジットカード利用の承認が下りた後、24時間以内（通常数分以内）</p>
                    `,
                    },
                    clause08: {
                        heading: "利用できる端末およびソフトウェアの動作環境",
                        html: `
                    {link}に記載されたモバイル端末およびその OS バージョン
                    `,
                    },
                    clause09: {
                        heading: "お支払い方法",
                        html: `
                    <p class="ts-quiet">クレジットカード（VISA, MasterCard, American Express）</p>
                    `,
                    },
                    clause10: {
                        heading: "お支払い時期",
                        html: `
                    <p class="ts-quiet">購入時</p>
                    `,
                    },
                    clause11: {
                        heading: "返品・交換・キャンセル",
                        html: `
                    <p class="ts-quiet">商品が未使用の場合、購入後7日以内に限り返品および返金を承ります。 商品不良の場合は購入後の期限に関わらず、返品および交換を行います。</p>
                    `,
                    },
                },
            },
        },
    },
}
</script>
